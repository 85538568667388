






import {Vue, Component} from 'vue-property-decorator';
import AideConges from '@/components/Aide/AideConges.vue';


@Component({
    components: {
        AideConges
    }
})
export default class extends Vue {}
